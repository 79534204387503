/* .headerParent {
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);

  width: 100%;
}

.headerContainer {
  width: 90%;
  display: flex;
  margin: auto;
  align-items: center;
} */
.headerParent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  background-color: rgb(0 0 0 / 51%);
  /* background-image: url('../images/Logo\ -\ Text\ Only.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain; */
  /* background-color: black; */
}

.headerContainer {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 88px;
}
.headerLeft > h1 {
  color: white;
  
}

.headerLeft {
  margin-right: auto;
}

.headerUl {
  color: white;
  display: flex;
  gap: 50px;
  list-style: none;
  width: 75%;
  align-items: center;
}
.headerUl>li{
    font-size: 20px;
    cursor: pointer;
    opacity: .6;
    
}
.headerUl>li:hover{opacity: 1;}
.headerRight {
  display: flex;
  width: 70%;
  /* width: 86%; */
  align-items: center;
  justify-content: space-evenly;

}
.headerBtnsContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 20px;
}
.headerBtnsContainer > button {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: bold;
}
.headerBtnsContainer > button:hover {
  background-color: var(--button-color);
  cursor: pointer;

  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
}

.userImgHeader {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}


.dropdown{color:white}


   ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    
  }
  
  li {
    float: left;
    
    
  }
  button,a{cursor: pointer;}
  
  li a, .dropbtn {
    display: flex;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    
  }
  
  li a:hover, .dropdown:hover .dropbtn {
    
    cursor: pointer;
  }
  
  li.dropdown {
    display: inline-block;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    transition: 0.5s;

    
  }

  /* .dropdown-content:hover a{
    background: linear-gradient(#3030bf, #59aeff);

  } */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    background: white;
    border-radius: 10px;
    transition: 0.5s;

  }
  
 
  
  .dropdown:hover .dropdown-content {
    display: block;
    transition: 0.5s;

  }
 

  @media only screen and (max-width: 1300px) {
    .headerParent{display: none;}
  }
.singleNotification{
  display: flex;
  align-items: flex-start;
  color: black;
  justify-content: center;
  column-gap: 5px;
  padding: 20px;
  border-bottom: 1px solid rgb(97, 97, 97);
}
  
.singleNotification>span{
  font-size: 16px;
  /* border-right: 2px solid black; */
  padding-right: 5px;
  
}
  .singleNotification:hover{
    background: linear-gradient(#3030bf, #59aeff);
    color: white;
  }