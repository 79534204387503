.loginGoogleBtn {
  width: "100%";
  color: "#5a5959";
  background-color: "white";
  display: flex;
  justify-content: "center";
  gap: 7px;
  padding: 15px 10px;
  border-radius: 4px;
  margin: 10px 0 20px 0;
  border: 0.01em solid #cbc7c7;
  font-size: 1.1em;
  font-weight: bold;
  cursor: pointer;
}
.h1ForRegistration{font-size: 34px;}
.parentRegistration {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: rgb(9, 14, 52);
  justify-content: center;
  align-items: center;
}
label{
    font-weight: 500;
}
.username{
    display: flex;
    flex-direction: column;
}
.registerContainer {
  background-color: white;
  width: 70%;
  display: flex;
  justify-content: space-between;
  padding: 30px;
  align-items: center;
  border-radius: 10px;
  
}
.right {
  width: 44%;
  display: flex;
  justify-content: center;
  
}
.right > img {
  width: 100%;
  max-width: 700px;
}
.left {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.NameAndUserName > div {
    width: 47%;
  display: flex;
  flex-direction: column;
}
.passwordAndConfirm > div {
  display: flex;
  flex-direction: column;
}
.emailForm {
  display: flex;
  flex-direction: column;
}
.NameAndUserName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.passwordAndConfirm {
  display: flex;
  flex-direction: column;
}
.registrationForm{
  width: 97%;
  overflow: auto;
  max-height: 550px;
  padding: 5px;
}
.registrationForm input {
  border-radius: 4px;
  border: 0.01em solid #cbc7c7;
  padding: 10px 10px;
  margin: 5px 0 0 0;
  font-size: 18px;
  background-color: #eee;
  margin-bottom: 10px;
  color: rgb(28, 28, 28);
}
.genderSelect{
  border-radius: 4px;
  border: 0.01em solid #cbc7c7;
  padding: 10px 10px;
  margin: 5px 0 0 0;
  font-size: 18px;
  background-color: #eee;
  margin-bottom: 10px;
  color: rgb(28, 28, 28);
}
.loginGoogleBtn{
    background-color: white;
}
.registrationForm input:focus,.genderSelect:focus{

outline: none ;
    
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);}

    .registrationButtons{
        display: flex;
        justify-content: space-evenly;
        margin-top: 20px;
    }
    .createAccountbtn, .createAccountbtnWithGoogle{
        width: 45%;
        display: flex;
        justify-content: center;
        gap: 7px;
        padding: 15px 10px;
        border-radius: 4px;
        margin: 10px 0 20px 0;
        border: 0.01em solid #cbc7c7;
        font-size:1.1em;
        font-weight:bold;
        cursor:pointer;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
       
    }
    .createAccountbtn{
        background-color: rgb(48, 86, 209);
        color: white;
    }
    .createAccountbtn:hover{background-color: rgb(35 64 157)}
    .createAccountbtnWithGoogle{
        background-color: white;
    }
    .createAccountbtnWithGoogle:hover{background-color: #eee}

::placeholder{
    font-size: 16px;
}



@media only screen and (max-width:1190px) {
  .createAccountbtnWithGoogle,.createAccountbtn{font-size: 0.7em;display: flex;align-items: center;padding: 10px 5px;}
  
}

@media only screen and (max-width:992px) {
  .registerContainer{flex-direction: column-reverse;height: fit-content;}
  .left{width: 90%;}
  .right{width: 50%;}
  .h1ForRegistration{text-align: center;}
  .createAccountbtnWithGoogle,.createAccountbtn{font-size: 1em;padding: 10px 10px;}
  
}

@media only screen and (max-width:680px) {
  .registrationButtons{flex-direction: column;align-items: center;}
  .registrationButtons>button{width: 75%;}
  ::placeholder{font-size: 12px;}
  
}

@media only screen and (max-width:450px) {
  .registerContainer{width: 80%;}
  
}