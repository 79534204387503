.ContainerAllQuizes{
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 130px auto;
    flex-wrap: wrap;
    gap: 20px;
}

.ContainerDataForQuiz{
    width: 45%;
    background-color: aliceblue;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
@media only screen and (max-width:1115px) {
    .ContainerAllQuizes
    {
        justify-content: center;
    }
    .ContainerDataForQuiz{
        width: 80%;
    }
    }
   

