.modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
  
  .modal-content {
    width: 50%;
    background-color: #fff;
    padding: 20px;
  }
  
  .modal-content span {
    display: block;
    margin-bottom: 10px;
  }
  
  .modal-content input,
  .modal-content select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .modal-content button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    background-color: #4CAF50;
    color: #fff;
    cursor: pointer;
  }
  
  .modal-content button:hover {
    background-color: #45a049;
  }
  
  .modal-content button:last-child {
    background-color: #f44336;
  }
  
  .modal-content button:last-child:hover {
    background-color: #d32f2f;
  }
  