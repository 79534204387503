
@media only screen and (max-width: 1300px) {
  .sideBar {
    display: block !important;
    background-color: #ccc;
  }
  .sidebarClodeIcon {
    display: block !important;
    position: absolute;
    top: 6px;
    right: 3px;
  }
}


@media only screen and (max-width: 500px) {
  .sideBarName{
    display: none;
  }
}
