
.AddQuizToSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: white;
}
.InputInQuestion{
  border: none;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  /* height: 100px; */
  resize: none;
  text-transform: capitalize;
  outline: none;
}
.quizHeaderAndBtn {
  margin-top: 50px;
  width: 100%;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  /* justify-content: center; */
}
.quizHeaderAndBtn h2{
  text-align: center;
  font-weight: 600;

}
.quizHeaderData {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
}
.quizHeaderData h1 ,.quizHeaderData h2{
  margin: 0;
}
.quizHeaderOneLine {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
}
.ContInputs{
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}
.quizHeaderOneLine > input {
  width: 150px;
  padding: 10px 10px;
  font-size: 16px;
  color: #505050;
  /* margin-bottom: 30px; */
  /* margin-left: 0px; */
  border: none;
  border-bottom: 1px solid #141e30;
  outline: none;
  border-right: none;
  background: transparent;
}
.quizHeaderOneLine span{
  font-size: 18px;
  font-weight: bold;
}

.quizQiestionsDiv {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  column-gap: 50px;
  flex-direction: column;
  row-gap: 20px;
  flex-wrap: wrap;
  align-items: center;
}
.ContQuestionTempAndAddQ{
  display: flex;
  align-items: center;
  width: 90%;
  flex-direction: column;
  gap: 20px;
  margin: 50px;
}
.ConquizHeaderAndBtnquizQiestionsDiv{
  margin-top: 130px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  width: 70%;
}
/* .QestionTemplate{
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
}

.questionInput{
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.questionInput>input{
  width: 200px;
  padding-left: 10px;
  height: 30px;
} */
.QestionTemplate {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  row-gap: 20px;
  margin-bottom: 20px;
}
.ContQuestionScore{
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 20px;
}
.W100{
  width: 100px;
}
.DeleteBtnAnswer{
  font-size: 18px;
  padding: 10px 20px;
  background-color: rgb(207, 21, 21);
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
}
.addQuestionbtn{
 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: rgb(20, 36, 158);
  color: white;
  font-weight: bold;
  row-gap: 20px;
  padding: 10px;
  font-size: 14px;
  transition: all 0.3s ease;
  align-self: flex-end;
}

.addQuestionbtn>span{
  font-size: 40px;
}

.addQuestionbtn:hover{
  box-shadow: 0 0 40px rgba(0,0,0,0.5);
 
}
/* .questionInput {
  display: flex;
  margin-bottom: 10px;
}

.questionInput span {
  margin-right: 10px;
  font-size: 18px;
}

.questionInput input[type="number"],
.questionInput input[type="text"] {
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 400px;
} */

.questionInput {
       /* width: 150px; */
    /* padding: 10px 10px; */
    font-size: 16px;
    color: #505050;
    /* margin-bottom: 30px; */
    margin-left: 0px;
    border: none;
    outline: none;
    /* border-right: none; */
    background: transparent;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    gap: 10px;
    /* margin-bottom: 10px; */
    width: 100%;
}
.questionInput1 {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
.questionInput span ,.questionInput1 span {
  font-size: 1.2em;
  font-weight: bold;
  color: black;
}
.ScoreInAddQuis{
  width: 40px;
  /* width: 100%; */
  padding: 10px 10px;
  font-size: 17px;
  color: #323232;
  /* margin-bottom: 30px; */
  /* margin-left: 10px; */
  border: none;
  border-bottom: 1px solid #141e30;
  outline: none;
  border-right: none;
  letter-spacing: 1px;
  background: transparent;
}
.ContAnswerCard{
  display: flex;
  gap: 15px;
  align-items: flex-end;
  width: 60%;
}
.ContAnswerCard .Radio{
  cursor: pointer;
}
.ContQuestions{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-height: 400px;
  overflow: auto;
}

.QestionTemplate .btnG {
  font-size: 18px;
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
}

.AddNewAnswerSaveQuestion{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 60%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.AddNewAnswerWithHover{
  padding: 8px;
  border: 1px solid var(--darkgreen-color);
  color: var(--darkgreen-color);
  font-size: 16px;
  background-color: transparent;
  cursor: pointer;
}
.AddNewAnswerWithHover:hover{
  background-color: var(--darkgreen-color);
  color: var(--background-color);
}

.SaveQuestionWithHover{
  padding: 8px;
  border: 1px solid var(--orange-color);
  color: var(--orange-color);
  font-size: 16px;
  background-color: transparent;
  cursor: pointer;
}
.SaveQuestionWithHover:hover{
  background-color: var(--orange-color);
  color: var(--background-color);
}
.FaTrash{
  width: 25px;
  height: 25px;
  color: #c02121;
}
.FaTrash:hover{
  color: #9b1b1b;
  cursor: pointer;
}
.InputWithDelete{
  width: 100%;
  padding: 5px 10px;
  font-size: 17px;
  color: #323232;
  /* margin-bottom: 30px; */
  /* margin-left: 10px; */
  border: none;
  border-bottom: 1px solid #141e30;
  outline: none;
  /* border-right: none; */
  letter-spacing: 1px;
  background: transparent;
  resize: none;
}
.ContSubmitAndAddQues{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.container{
    width: 100%;
    height: 100vh;
    background: #e3ebfe;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnSubmitted{
    width: 270px;
    height: 80px;
    border: none;
    outline: none;
    background: #2f2f2f;
    color: #fff;
    font-size: 22px;
    border-radius: 40px;
    text-align: center;
    box-shadow: 0 6px 20px -5px rgba(0,0,0,0.4);
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.check-box{
    width: 80px;
    height: 80px;
    border-radius: 40px;
    box-shadow: 0 0 12px -2px rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    right: -40px;
    opacity: 0;
}

.check-box svg{
    width: 40px;
    margin: 20px;
}

svg path{
    stroke-width: 3;
    stroke: #fff;
    stroke-dasharray: 34;
    stroke-dashoffset: 34;
    stroke-linecap: round;
}

.active{
    background: #ff2b75;
    transition: 1s;
}

.active .check-box{
    right: 0;
    opacity: 1;
    transition: 1s;
}

.active p{
    margin-right: 125px;
    transition: 1s;
}

.active svg path{
    stroke-dashoffset: 0;
    transition: 1s;
    transition-delay: 1s;
} */

.buttons-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonbtn {
     /* width: 150px; */
    /* height: 150px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border-radius: 10px; */
    background-color: transparent;
    color: #182f5d;
    font-weight: bold;
    row-gap: 20px;
    padding: 10px;
    border: 1px solid #182f5d;
    font-size: 16px;
    transition: all 0.3s ease;
    align-self: flex-end;
}

.button-arounder {
  font-size: 1rem;
  background: hsl(190deg, 30%, 15%);
  color: hsl(190deg, 10%, 95%);
  
  box-shadow: 0 0px 0px hsla(190deg, 15%, 5%, .2);
  transform: translateY(0);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  --dur: .15s;
  --delay: .15s;
  --radius: 16px;
  
  transition:
    border-top-left-radius var(--dur) var(--delay) ease-out,
    border-top-right-radius var(--dur) calc(var(--delay) * 2) ease-out,
    border-bottom-right-radius var(--dur) calc(var(--delay) * 3) ease-out,
    border-bottom-left-radius var(--dur) calc(var(--delay) * 4) ease-out,
    box-shadow calc(var(--dur) * 4) ease-out,
    transform calc(var(--dur) * 4) ease-out,
    background calc(var(--dur) * 4) steps(4, jump-end);
}

.button-arounder:hover,
.button-arounder:focus {
  box-shadow: 0 4px 8px hsla(190deg, 15%, 5%, .2);
  transform: translateY(-4px);
  color: white;
  background: hsl(146, 83%, 16%);
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}

@media only screen and (max-width:800px) {
  
  .ContQuestionTempAndAddQ{
    width: 100%;
  }
  .ContQuestionScore{
    flex-direction: column;
    align-items: flex-start;
  }
  .quizHeaderData>h2{
    font-size: 1em;
  }
  
  }