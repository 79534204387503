.AllDataInRatePage{
    margin-top: 130px;
    margin-bottom: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
.ContainerForCardsRate{
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 310px;
}
.ContainerForCardsRate1{
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
   
}
.CardRate{
    display: flex;
    flex-direction: column;
    /* background-color: #305da0; */
    width: 300px;
    height: 300px;
    justify-content: space-between;
    border-radius: 10px;
    background: linear-gradient(45deg, #262692, #346da0);
    color: white;
}

.padding20L{
    padding-left: 20px;
}
.HoverAssignBtn{
    transition: 0.5s;
    background-color: white;}
.HoverAssignBtn:hover{
    background-color: #346da0;
    color: white;
    
}

.DivBTNINassignTocourse{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.AllAssignData{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.DivSteps{
        display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.imagesStepsDiv{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.imgSteps{
    max-width: 400px;
    width: 100%;
    height: 200px;
}
.Tips{
        font-size: 50px;
    letter-spacing: 4px;
    background: linear-gradient(to right, #FCC11E, #d6b24d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Tips2{
        width: 180px;
    text-align: center;
    font-size: 34px;
    /* color: var(--button-color); */
}