
.createCourseContainer {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.createCourseForm {
  width: 800px;
 
  background-color: var(--background-color);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 30px;
}
.createCourseForm > h1 {
    color: var(--darkgreen-color);
    text-align: center;
    width: 100%;
}

.createCourseFormOneLine {
  color: var(--darkgreen-color);
  font-size: 18px;
}
.createCourseFormOneLineTextarea  {
   width: 100%;
    /* box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white; */
    /* box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white; */
  
}
.textareainput:focus{
outline: none;}
.SelectSkill{
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #efefef;
    color: var(--darkgreen-color);
    font-size: 16px;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
}
.textareainput {
     
    border: none;
    border-bottom: 1px solid #141e30;
   
    width: 96%;
    padding: 10px;
   
    background-color: transparent;
    resize: none;
    color: #555;
    font-size: 18px;
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out;
}
.createCourseFormTwoInlineDiv {
    display: flex;
    width: 100%;
    align-items: baseline;
    column-gap: 10px;
    row-gap: 30px;
    justify-content: space-around;
}
.createCourseFormTwoInlineDiv > div {
  width: 45%;
}
.createCourseFormTwoInlineDiv > div > input {
    width: 90%;
    padding: 14px 10px;
    font-size: 16px;
    color: #243b55;
    /* margin-bottom: 30px; */
    /* margin-left: 0px; */
    border: none;
    border-bottom: 1px solid #141e30;
    outline: none;
    padding-left: 15px;
    height: 13px;
    border-right: none;
    background: transparent;
    /* box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white; */
    /* box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white; */}

.createCourseBtnDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.createCourseBtnDiv > button {
  background-color: var(--darkgreen-color);
  color: white;
  height: 50px;
  border: none;
  width: 200px;
  border-radius: 7px;
  font-size: 26px;
  font-weight: bold;
}
.createCourseBtnDiv > button:hover{
    border: 1px solid var(--darkgreen-color);
    color: var(--darkgreen-color);
    background-color: var(--background-color);
}
.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input[type="number"] {
  -moz-appearance: textfield;
}

.select-field-skill{
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #efefef;
    color: #555;
    font-size: 16px;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
}
.conAllTextareaCreateCourse{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.createCourseFormOneLineNew{
    width: 96%;
}
.createCourseFormOneLineNew label{
    color: var(--darkgreen-color);
    margin-bottom: 5px;
    font-size: 18px;
}

.driveLink{
  width: 96%;
  padding: 14px 10px;
  font-size: 16px;
  color: #243b55;
  /* margin-bottom: 30px; */
  /* margin-left: 0px; */
  border: none;
  border-bottom: 1px solid #141e30;
  outline: none;
  /* padding-left: 15px; */
  height: 13px;
  border-right: none;
  background: transparent;
  /* box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white; */
  /* box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white; */
}