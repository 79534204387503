@media only screen and (max-width:550px) {
  
    .buyNowContainer>h2{
        font-size: 18px !important;
        margin-bottom: 20px !important;
    }
    .buyNowContainer>h4{
        font-size: 20px !important;
        margin-bottom: 20px !important;
    }
    .buyNowContainer>span{
        font-size: 18px !important;
        margin-bottom: 20px !important;
    }
    .buyNow{
        display: flex !important;
        flex-direction: column !important;
        border-radius: 0 !important;
    }
    
    .h1Size{
        font-size: 12px !important;
    }
    
    
    }